import * as React from "react";

type CheckboxType = {
    id: string,
    label: string,
    value: string,
    handleChecked: (event: any) => any
}

export default function CheckBox({id, label, value, handleChecked} : CheckboxType) {
    return (
        <>
            <label
                className='bg-light mx-1 btn'
                htmlFor={id}
            >
                <input
                    id={id}
                    type='checkbox'
                    name='body_cars[]'
                    value={value}
                    className='mx-1'
                    onChange={(event => handleChecked(event)) }
                />
                {label}
            </label>
        </>
    )
}
