import * as React from "react"
import {faBox, faChair, faCarBattery, faCar, faDriversLicense} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from './Vehicle.module.css'
import {ImageSlider} from "../index";

type VehicleInfoType = {
    seat: string | number,
    energy: string,
    bodyCar: string,
    box?: string,
    withDriver?: boolean,
    equipments?: [],
    medias?: Array<any> | undefined,
    description?: string | null,
}


export default function VehicleInfo({seat, energy, bodyCar, box, withDriver, equipments, medias, description}: VehicleInfoType) {

    return (
        <>
            <div className='mb-4'>
                <ImageSlider images={medias} />
            </div>
            <div className='row mb-4 small'>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faBox} />
                    <span className='px-2'>{box}</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faChair} />
                    <span className='px-2'>{seat} places</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faCarBattery} />
                    <span className='px-2'>{energy}</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faCar} />
                    <span className='px-2'>{bodyCar}</span>
                </div>
            </div>

            {withDriver && (
                <div className='alert alert-success text-center font-weight-bold'>
                    <FontAwesomeIcon className='px-2' icon={faDriversLicense} />
                    Véhicule fourni avec un conducteur
                </div>
            )}

            {description && (
                <div className='my-3'>
                    <h6>Description</h6>
                    <div className='text-muted'>{description}</div>
                </div>
            )}


            <h5>Les équipements de ce véhicule</h5>

            <div className='my-3'>
                {equipments?.map(({id, name}: {id: string, name: string}) => (
                    <span key={id} className={styles.Badge}>{name}</span>
                ))}
            </div>
        </>
    )
}
