import React from 'react'
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface SliderSettings extends Settings {}

export default function ImageSlider ({images}: {images: Array<any> | undefined}) {

    const settings: SliderSettings  = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    }

    if (images === undefined || images.length === 0) {
        return null
    }

    return (
        <Slider {...settings}>
            {images.map(({id, url, fileName, lastModified}: {id: string, fileName: string, url: string, lastModified?: string}) => (
                <div key={id}>
                    <img className='rounded' loading="lazy" src={url} alt={fileName} style={{width: '100%'}} />
                    <div className='text-center small text-muted'>
                        <small>Photo mise à jour le {lastModified}</small>
                    </div>
                </div>
            ))}
        </Slider>
    )
}

