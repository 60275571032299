import React, {useState} from "react"
import styles from './InputText.module.css'

type InputTextType = {
    label: string | undefined;
    text: string | undefined;
    uid: string | undefined;
    onChanged: (text: string) => void
    data?: Array<any>
}

export default function Select({label, text, onChanged, uid, data = []}: InputTextType) {

    const [value, setValue] = useState<string | any>(text)

    const handleChanged = (event: any) => {
        setValue(event?.target?.value)
        onChanged(event?.target?.value)
    }

    return (
        <div className='form-group my-2'>
            <label className={styles.label} htmlFor={uid}>
                {label}
            </label>
            <select defaultValue={value} onChange={handleChanged} className='form-control form-select py-2 bg-light'>
                <option value={value}>{''}</option>
                {data?.map((item: {id: string, title: string}) => (
                    <option key={item.id} value={item.id}>
                        {item.title}
                    </option>
                ))}
            </select>
        </div>
    )
}
