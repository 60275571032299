import * as React from "react";
import {AppLayout} from "../layouts";
import {Definition, VehicleList} from "../components"
import {_definitions} from '../data/index'

export default function HomeScreen() {

    return (
        <AppLayout appBarType='home'>
            <React.Fragment>
                <div className='container'>
                    <VehicleList />
                    <div className='row my-4'>
                        {_definitions.map((item: any, key: any) => (
                            <Definition key={key}{...item}/>
                        ))}
                    </div>

                </div>
            </React.Fragment>
        </AppLayout>
    );
}
