const _definitions: any = [
    {
        title: '1. Recherchez votre véhicule',
        text: 'Découvrez notre sélection de véhicules de location sur mesure : Plusieurs marques disponibles sur notre catalogue',
        imgUrl: '/definitions/d1.jpg'
    },

    {
        title: '2. Demandez une prise en charge',
        text: 'Contactez nous directement sur nos réseaux sociaux Facebook, WhatsApp pour une prise en charge personnalisée',
        imgUrl: '/definitions/d2.jpg'
    },

    {
        title: '3. Réservez en ligne',
        text: 'Réservez directement en ligne depuis le formulaire sur la fiche du véhicule qui vous intéresse.',
        imgUrl: '/definitions/d3.jpg'
    },
]

export default _definitions
