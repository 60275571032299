import * as React from "react"
import VehicleItem from "./VehicleItem"
import {remoteGet} from "../../remotes/api"
import {Loader, SearchForm} from "../index"
import VehicleType from "./VehicleType"

const signal = new AbortController()

const _buildQueryString = (params: any | undefined) => {

    if (params === undefined) {
        return null
    }

    const queryString = [];

    for (const param in params) {
        if (params.hasOwnProperty(param)) {
            queryString.push(`${encodeURIComponent(param)}=${encodeURIComponent(params[param]?.join(','))}`)
        }
    }

    return queryString.join('&')
}

export default function VehicleList({searchInfo}: any) {

    const [list, setList] = React.useState([])
    const [loading, isLoading] = React.useState(true)
    const [fetching, isFetching] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [hasMore, setHasMore] = React.useState(true);
    const [queryString, setQueryString] = React.useState<any>({})

    function handleSearchInfos (infos: any) {

        setQueryString(infos)

        setList([])

        setPage(1)

        fetchData(infos)

    }

    React.useEffect(() => {

        fetchData()
        // scroll event listener
        window.addEventListener('scroll', handleScroll);

        // cleaning the use effet
        return () => {
            window.removeEventListener('scroll', handleScroll);
            signal.abort()
        };

    }, [])

    // update the component when page number change
    React.useEffect(() => {
        if (page !== 1) {
            fetchData()
        }

    }, [page])


    const fetchData = async (query?: any | undefined) => {

        try {

            isLoading(true)

            const response = await remoteGet(`vehicles?page=${page}&${ _buildQueryString(query ? query : queryString) }`, signal)
            const data = response.data as []
            const lastPage = response?.last_page as number

            console.log(lastPage, page)

            setList(prevList => [...prevList, ...data])

            if (parseInt(String(lastPage)) === parseInt(String(page)) || data.length === 0) {
                setHasMore(false);
            }

            isLoading(false)

            isFetching(false)

        } catch (error) {
            isLoading(false)
            isFetching(false)
        }

    }


    const handleScroll = () => {
        if (
            window.innerHeight + window.pageYOffset >= document.body.offsetHeight
            && hasMore
        ) {
            setPage(prevPage => prevPage + 1)
        }

    }

    return (
        <>
            <SearchForm onSearched={((searchedInfos: any) => {
                handleSearchInfos(searchedInfos)
            })} />

            <div className='row' id='list'>
                {list.length > 0 && (
                    list.map((vehicle: VehicleType) => <VehicleItem key={vehicle.id} {...vehicle}  />)
                )}
            </div>

            {loading && <Loader />}

            {/*{list.length > 0 && !fetching && hasMore && (
                <button className='btn btn-light w-100' onClick={handleLoadMore}>
                    Chargez plus de véhicules ...
                </button>
            )}*/}


            { (! loading &&  list.length === 0)  && <div className='text-center'>Aucun élément trouvé.</div>}
        </>
    )
}
