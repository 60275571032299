import * as React from "react"
// @ts-ignore
import { Helmet } from 'react-helmet'

type MetaHeadingType = {
    title: string,
    description?: string,
    imageUrl?: any
}

export default function MetaHeading({title, description , imageUrl}: MetaHeadingType) {

    const desc = description ?? 'Service de location de voiture et de logement'
    const url = imageUrl ?? '/logo.png'

    return <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta name="description" content={desc} />
        <meta property="og:image" content={url} />
        <title>{title}</title>
    </Helmet>
}
