const _bodyCars: any = [
    {
        id: 1,
        title: 'Berline',
        icon: '',
    },

    {
        id: 2,
        title: 'Break',
        icon: '',
    },

    {
        id: 3,
        title: 'Compacte',
        icon: '',
    },

    {
        id: 4,
        title: 'Mini van',
        icon: '',
    },

    {
        id: 5,
        title: 'SUV / 4x4',
        icon: '',
    },

    {
        id: 6,
        title: 'Van',
        icon: '',
    },

]

export default _bodyCars
