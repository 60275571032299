import * as React from "react"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker,  {setDefaultLocale } from "react-datepicker"
import fr from 'date-fns/locale/fr'
import './SelectDate.css'
import dayjs from "dayjs"

type SelectDateType = {
    handleDateChanged: ({}: {startDate: string, endDate: string, duration: number | null}) => void,
    startAt: Date,
    endAt: Date,
    diffInDay: number,
}

const _format = 'YYYY-MM-DD'
const _userFormat = 'DD/MM/YYYY'


export default function SelectDate({handleDateChanged, startAt, endAt, diffInDay}: any) {

    const [date, setDate] = React.useState<Date | null>(new Date())
    const [startDate, setStartDate] = React.useState<Date | undefined>(startAt)
    const [endDate, setEndDate] = React.useState<Date | undefined>(endAt)
    const [duration, setDuration] = React.useState<number | undefined>(diffInDay)


    setDefaultLocale('fr')

    const handleDateChange = (range: any) => {

        const [startDate, endDate] = range

        setStartDate(startDate)
        setEndDate(endDate)

        if (endDate && startDate) {

            const duration = dayjs(endDate).diff(startDate, 'days') + 1

            setDuration(duration)

            handleDateChanged({
                startDate: dayjs(startDate).format(_format),
                endDate: dayjs(endDate).format(_format),
                duration
            })
        }
    }

    return (
        <>
            <h6 className='text-uppercase text-success'>
                <small>Choisissez vos dates de réservation</small>
            </h6>
            <DatePicker
                selected={date}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                minDate={date}
                selectsRange
                dateFormat="dd/MM/yyyy"
                className='form-control form-control-lg bg-light text-center small'
                placeholderText='Choisir vos dates ici'
            />
            {duration && (
                <div className='text-muted px-1 mt-2 small text-center'>
                    <small>Réservation allant du {dayjs(startDate).format(_userFormat)} au {dayjs(endDate).format(_userFormat)}. <br/>Soit {duration} jour(s).</small>
                </div>
            )}
        </>
    )
}
