const _boxes: any = [
    {
        id: 1,
        title: 'Automatique',
        icon: '',
    },

    {
        id: 2,
        title: 'Manuelle',
        icon: '',
    },


]

export default _boxes
