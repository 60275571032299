import * as React from "react";

export default function Pricing({displayedPrice, currency}: {displayedPrice: string, currency: string}) {
    return (
        <>
            <h6>Prix de la location</h6>
            <h3 className='d-inline mr-2 text-danger'>
                <strong>{displayedPrice} {currency}</strong>
            </h3>
            <sup>Par jour</sup>
            <hr/>
        </>
    )
}
