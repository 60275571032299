import * as React from "react"
import {AppLayout} from "../layouts"
import {useParams} from "react-router"
import {Booking, Contact, Loader, MetaHeading, Pricing, VehicleInfo, VehicleTitle} from "../components"
import {remoteGet} from "../remotes/api"

const signal = new AbortController()

export default function VehicleScreen() {

    const [vehicle, setVehicle] = React.useState<any>({} as any)
    const [loading, isLoading] = React.useState<boolean>(true)

    let { slug } = useParams();

    React.useEffect(() => {
        remoteGet(`vehicles/${slug}`, signal).then(response => {
            setVehicle(response)
            isLoading(false)
        })

        return () => {
            setVehicle(null)
        }
    }, [])

    if (loading) {
        return <Loader />
    }

    return (
        <AppLayout>

            {vehicle && vehicle.id && (
                <MetaHeading title={vehicle?.designation} description={vehicle?.description} imageUrl={vehicle?.medias[0]?.url} />
            )}

            <React.Fragment>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 col-12'>
                            <VehicleTitle {...vehicle} />
                            <VehicleInfo  {...vehicle} />
                        </div>
                        <div className='col-md-4 col-12'>
                            <Pricing {...vehicle} />
                            <Booking vehicle={vehicle} />
                            {vehicle && vehicle.phone && vehicle.phone?.length > 0 && (
                                <>
                                    <hr/>
                                    <div className='small text-muted my-2 text-center'>
                                        Demandez une prise en charge directement en nous appelant directement.
                                    </div>
                                    <Contact {...vehicle} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </AppLayout>
    )
}
