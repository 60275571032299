import * as React from "react"
import {Link} from "react-router-dom"
import {faHandshake} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from  '../components/Banner/Banner.module.css'

export default function HomeAppBar() {
    return (
        <div>
            <Link to={'/become-partner'}>
                <BecomePartner />
            </Link>
            <header className={styles.banner} style={{height: 450, backgroundImage: `url(/driver.jpg)`}}>
                <div className='container'>
                    <nav className="navbar navbar-expand-lg fixed-top bg-succes">
                    <span className="navbar-brand">
                            <a href='/'>
                                <img src='/logo.png' alt="Logo Locabu" width={130} />
                            </a>
                        </span>

                        <span className="navbar-toggler border-0">
                            {/*<a className='btn btn-outline-success' href="https://pim.locabu.fr" target='_blank'>
                                <small>Publier une annonce</small>
                            </a>*/}
                        </span>
                    </nav>
                </div>

                <h1 className='text-center px-1'>Trouvez votre voiture de location au Burundi</h1>
            </header>
        </div>

    )
}

function BecomePartner() {
    return (
        <div className='d-flex justify-content-between p-2 bg-white align-items-center'>
            <div className='px-3'>
                <FontAwesomeIcon className='fa-2x' icon={faHandshake} />
            </div>
            <div className='w-100'>
                <h6 className='font-weight-bold mb-0'>Devenez partenaire Locabu</h6>
                <div className='small'>Proposez-nous vos véhicules et gagnez de l'argent.</div>
            </div>
        </div>
    )

}
