import * as React from "react"
import styles from './Vehicle.module.css'
import {Link} from "react-router-dom"
import VehicleType from "./VehicleType"
import {Contact, ImageSlider} from "../index"
import {faBook} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


export default function VehicleItem({id, slug, designation, price, brand, currency, box, energy, seat, medias, bodyCar, phone, displayedPrice}: VehicleType) {
    return (
        <div  className='col-md-4 col-12 mb-5'>
            <div>
                <div className='mb-4'>
                    <ImageSlider images={medias} />
                </div>

                <div className='d-flex justify-content-between'>
                    <h6 className='w-100'>{designation}</h6>
                    <h6 className='w-100 d-flex justify-content-end'>
                        <strong className='text-danger h6'>{displayedPrice} {currency}</strong> <sup className='font-weight-light'>/ jour</sup>
                    </h6>
                </div>

                <div className='small'>
                    <div>
                        <span className={styles.Badge}>Boîte {box?.toLowerCase()}</span>
                        <span className={styles.Badge}>{energy}</span>
                        <span className={styles.Badge}>{seat} places</span>
                        <span className={styles.Badge}>{bodyCar}</span>
                    </div>
                </div>

                <div className='d-flex justify-content-between my-2 align-items-center'>
                    <div className='px-1 w-100'>
                        {phone && phone.length > 0 && <Contact id={id} phone={phone} slug={slug}/>}
                    </div>
                    <div className='px-1 w-100'>
                        <Link className='text-decoration-none text-dark' to={`v/${slug}`}>
                            <button className='btn btn-success text-uppercase w-100'>
                                <FontAwesomeIcon className='px-2' icon={faBook} />
                                <small>Réservez</small>
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

