import * as React from "react"

export default function SimpleAppBar() {
    return (
        <header className='mb-4'>
            <nav className="navbar navbar-expand-lg fixed-top mask-custom shadow-0">
                <div className="container">
                    <span className="navbar-brand">
                        <a href='/'>
                            <img src='/logo.png' alt="Logo Locabu" width={120} />
                        </a>
                    </span>

                    <span className="navbar-toggler border-0">
                        {/*<a className='btn btn-outline-success' href="https://pim.locabu.fr" target='_blank'>
                            <small>Publier une annonce</small>
                        </a>*/}
                    </span>
                </div>
            </nav>
        </header>
    )
}
