import * as React from "react";
import styles from './Definition.module.css'

export default function Definition({title, text, imgUrl} : any) {
    return (
        <div className='col-md-4 col-12 mb-2'>
            <div className={styles.cover} style={{backgroundImage: `url(${imgUrl})`}}></div>
            <h2 className='h4'>{title}</h2>
            <p>{text}</p>
        </div>
    )
}
