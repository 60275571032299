import * as React from "react";
import {AppLayout} from "../layouts"
import {MetaHeading} from "../components"

export default function BecomePartnerScreen() {


    return (
        <AppLayout>
            <MetaHeading
                title='Devenir Partenaire Locabu'
                description='Découvrez de nouvelles opportunités pour maximiser vos revenus !'
                imageUrl='/driver2.jpeg'
            />
            <React.Fragment>
                <div className='container'>
                    <h1 className='h3'>Devenez partenaire Locabu</h1>
                    <p>Découvrez de nouvelles opportunités pour maximiser vos revenus ! </p>
                    <p>Louez votre véhicule en toute simplicité avec <b>Locabu.bi</b>. </p>
                    <p>Notre plateforme de location vous permet de gagner de l'argent en mettant votre véhicule entre les mains expertes de nos administrateurs</p>
                    <p>Profitez de notre réseau de clients potentiels, de notre expertise en gestion de location, et laissez-nous nous occuper de tout, du début à la fin.</p>
                    <p>Faites confiance à <b>Locabu.bi</b> pour une expérience de location transparente, sécurisée et lucrative.</p>

                    <h2 className='h3'>Contactez-nous dès maintenant sur WhatsApp</h2>
                    <p>Contactez-nous dès maintenant pour une prise en charge de votre véhicule.</p>
                    <p className='text-center'>
                        <a href="tel:+257 68 64 91 05">
                            <img src='/whatsapp.png' alt="Logo Locabu" width={120} />
                        </a>
                        <br/>
                        <strong className='text-uppercase'>Appelez-nous !</strong>
                    </p>
                </div>
            </React.Fragment>
        </AppLayout>
    );
}
