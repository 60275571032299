import React, {useState} from "react"
import styles from './InputText.module.css'

type InputTextType = {
    label: string | undefined;
    text: string | undefined;
    uid: string | undefined;
    type?: string | undefined;
    placeholder?: string | undefined;
    onChanged: (text: string) => void
}

export default function InputText({label, text, onChanged, uid, placeholder, type = 'text'}: InputTextType) {
    const [value, setValue] = useState<string | undefined>(text)

    const handleChanged = (inputText: string) => {
        setValue(inputText)
        onChanged(inputText)
    }
    return (
        <div className='form-group my-2'>
            <label className={styles.label} htmlFor={uid}>{label}</label>
            <input
                type={type}
                id={uid}
                className="form-control text-right bg-light py-2"
                placeholder={placeholder}
                onChange={event => handleChanged(event.target.value)}
                defaultValue={value}
            />
        </div>
    )
}
