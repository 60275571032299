import * as React from "react"
import {fakeAuthProvider} from "../auth"
import {useNavigate, Routes} from "react-router-dom"
import {decode} from 'js-base64';
import {remoteLogin, remotePost, URL} from "../remotes/api"
import CookieConsent, { Cookies } from "react-cookie-consent"

interface AuthContextType {
    online?: boolean;
    sync?: boolean;
    logged?: boolean;
    token: string | undefined;
    user: any;
    signIn: (login: string, password?: string | null, controller?: any) => Promise<any>;
    signOut: (callback: VoidFunction) => void;
    register: (credentials: any) => Promise<any>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {

    const [token, setToken] = React.useState<string | undefined>(undefined)
    const [user, setUser] = React.useState<any>(null)
    const [online, isOnline] = React.useState<boolean>(true)
    const [loading, isLoading] = React.useState<boolean>(false)
    const [logged, isLogged] = React.useState<boolean>(false)

    const navigate = useNavigate();

    const handleAccept = (): void => {
        // Gérer l'action lorsque les cookies sont acceptés
        console.log("Cookies acceptés");
    }


    React.useEffect(() => {
        const token: string = window.localStorage.getItem('@token') as string
        if (token) {
            // Set the user token
            setToken(token)
            // Lunch the loader component
            isLogged(true)
            // Redirect when token is empty
            navigate('/', { replace: true })
        }

    }, [loading])

    const signIn = (login: string, password?: string | null, controller?: any) => {

        return remoteLogin({login, password}, controller).then( response => {
            if (response && response.data?.number) {
                setToken(response.data?.number.id);
                setUser(true);
                window.localStorage.setItem('@token', response.data?.id)
                return true;
            }

            return false;
        })
    };

    const signOut = (callback: VoidFunction) => {
        return fakeAuthProvider.logout(() => {
            setToken(undefined);
            setUser({});
            callback();
        });
    };

    const register = (credentials: any) => {
        return remotePost('https://jsonplaceholder.typicode.com/posts', credentials).then(data => {
            setToken('451');
            setUser(true);
            window.localStorage.setItem('@token', '451')
            //console.log(credentials)
            return true;
        })
    };

    const value = { token, user, signIn, signOut, register, online, logged, isLoading, setToken, setUser};

    if (loading) {
        return <p>Loading ...</p>
    }

    return <AuthContext.Provider value={value}>
        <div>
            <CookieConsent
                location="bottom"
                buttonText="ACCEPTEZ"
                cookieName="locabuCookieConsent"
                style={{ background: "#222", color: "#fff" }}
                buttonStyle={{ background: "#f00", color: "#fff", fontSize: "13px" }}
                onAccept={handleAccept}
            >
                Ce site utilise des cookies <a className='d-none text-info' href='/cookies'>(consulter la liste)</a> pour améliorer votre expérience. En continuant à naviguer, vous acceptez notre utilisation des cookies.
            </CookieConsent>
            {children}
        </div>
    </AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
