import axios from "axios"

axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
// const URL = 'https://pim-locabu.test/api'
const URL = 'https://pim.locabu.fr/api'


function remotePost(path, data, controller) {
    return axios.post(URL + '/' + path, data, {signal:controller?.signal}).then(response => response.data)
}

function remoteSync(path, data, controller) {
    return axios.post(URL + '/' + path, data, {signal:controller?.signal}).then(response => response.data)
}

function remoteLogin(data, controller) {
    return axios.post(URL + '/users/login', data, {signal : controller?.signal}).then(response => response.data)
}

function remoteGet(path, controller) {
    return axios.get(URL + '/' + path, {signal:controller?.signal}).then(response => response.data)
}

export {
    remotePost,
    remoteLogin,
    remoteGet,
    remoteSync,
    URL
}
