import * as React from "react";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export default function GoBack({url, type = 1} : any) {
    return (
        <>
            <a href={url}>
                <FontAwesomeIcon className='fa-2x' icon={faArrowLeft} />
            </a>
        </>
    )
}
