import * as React from "react"
import {faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {remotePost} from "../../remotes/api";
import {Loader} from "../index";
const signal = new AbortController()

type ContactType = {
    id?: string,
    slug: string,
    phone?: string
}

export default function Contact({slug, phone}: ContactType) {

    const [loading, isLoading] = React.useState<boolean>(false)
    const [sent, isSent] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        return () => signal.abort()
    }, [])

    const handleContact = async () => {

        isLoading(true)

        try {

            const response = await remotePost(`vehicles/${slug}/contact`, {}, signal) as any

            if (response && (response?.sent)) {
                isSent(true)
            }

        } catch (error) {
            setError("Une erreur est survenue.")
            isSent(false)
        } finally {
            isLoading(false)
        }

    }

    if (error && error.length > 0) {
        return <div className='small text-danger text-center'>{error}</div>
    }

    if (loading) {
        return <Loader text='Demande en cours ...'/>
    }

    return (
        <>
            {! sent && (
                <button className='btn btn-warning w-100 text-uppercase' onClick={handleContact}>
                    <FontAwesomeIcon className='px-2' icon={faEnvelope} />
                    <small style={{fontSize: 10}}>Contactez-nous</small>
                </button>
            )}

            {sent && (
                <a className='btn btn-warning w-100 text-uppercase' href={`tel:${phone}`} title="Cliquez pour lancer l'appel">
                    <strong><small>{phone}</small></strong>
                </a>
            )}
        </>
    )
}
