const _energies: any = [
    {
        id: 1,
        title: 'Essence',
        icon: '',
    },

    {
        id: 2,
        title: 'Diesel',
        icon: '',
    },


]

export default _energies
